.btn, input {
  box-shadow: none !important;
}

@import "~bootstrap/scss/bootstrap";

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  background: linear-gradient(to top right, #e6f2ff 10%, #66b0ff 65%, #007bff 125%);
}
